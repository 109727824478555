import styled from 'styled-components';

export const Wrapper = styled.div`
margin:0;
clear:both;
.carousel .slide .legend{position:relative;margin:0;left:auto;bottom:auto;width:100%;background:none;opacity:1;color: inherit; font-size: inherit;margin-bottom:30px;padding: 0 10px;}
.description{color:#5A5A5A}
.carousel .control-dots .dot{background:#000000;box-shadow: none;}
.carousel .thumbs-wrapper{margin:0}
h4{font-weight:bold};
/* ul{display:flex}
li{float:left;width: 20%;
    height: 270px;
    background-size: contain;
    background-repeat: no-repeat;background-repeat:no-repeat;
    @media (max-width: 991px) {
        height:220px;
 }
  
} */
.caption{position:absolute;bottom:0;left:0;right:0;background-image: linear-gradient(180deg, #FFFFFFB2, #FFFFFF);min-height:76px}
@media (max-width:600px) {
  li{ width:50%;
   height:600px;
  }
}
.react-multiple-carousel__arrow{z-index:0}
.item{
  height:300px;
  margin-top:150px;
}
.react-multi-carousel-list{height:350px}
.profile{position:absolute;bottom:8px;left:0;right:0;text-align:center;z-index:1}
.item-reflection{
  height:250px;
}
`