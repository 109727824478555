import React, { useContext, useState } from "react";
import { MapMobileWrapper } from "./map-mobile-view.style";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button, Card } from "react-bootstrap";
import Africa from "../../public/images/map-mobile/Africa.png";
import Asia from "../../public/images/map-mobile/Asia.png";
import Australia from "../../public/images/map-mobile/Australia.png";
import Europe from "../../public/images/map-mobile/Europe.png";
import NorthAmerica from "../../public/images/map-mobile/NorthAmerica.png";
import SouthAmerica from "../../public/images/map-mobile/SouthAmerica.png";
import { ArtistProfile } from "../../models/user";
import { useRouter } from "next/router";
import Link from "next/link";
import ReactPlayer from "react-player";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Wrapper } from "../collectionSlider/collectionSlider-style";
import { UserContext } from "../../contexts/UserContext";
import { ArtistContext } from "../../contexts/ArtistContext";
import Image from "next/image";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

export default function MapMobileView({ title }) {
    const [viewByLoc, setViewByLoc] = useState('');
    const [showArtist, setShowArtist] = useState(false);
    const Router = useRouter();
    const continents: any = [
        { name: 'North America', image: NorthAmerica },
        { name: 'South America', image: SouthAmerica },
        { name: 'Asia', image: Asia },
        { name: 'Europe', image: Europe },
        { name: 'Australia', image: Australia },
        { name: 'Africa', image: Africa }];

    const { allArtists } = useContext(ArtistContext);

    //  console.log("artist", allArtists, viewByLoc);

    const showArtistFunc = (value: string) => {
        if (viewByLoc == value && showArtist) {
            setShowArtist(false)
        } else {
            setShowArtist(true)
        }
    }

    const filteredArtist = allArtists.filter(a => a.region === viewByLoc);
    return (
        <>
            <MapMobileWrapper className="container-fluid bg-dark ">
                <p className="d-flex justify-content-center pt-3" style={{ color: 'white', fontSize: 22, }}>{title}</p>
                {/* <ResponsiveCarousel axis="horizontal" autoPlay centerMode showThumbs={false} infiniteLoop width={"100%"}> */}
                <Carousel responsive={responsive} keyBoardControl={true} centerMode autoPlay={false}>
                    {continents?.map((continent: any) => {
                        return (
                            <div key={continent.name}>
                                <div className="border border-3 border-white rounded-3" style={{ width: "95%", padding: 15, marginTop: 25, marginBottom: 25, cursor: "pointer" }} onClick={() => { showArtistFunc(continent.name); setViewByLoc(continent.name) }}>
                                    <img src={continent.image.src} alt={continent.name} className='w-100 h-100 ' />
                                    <p className="d-flex justify-content-center pt-3" style={{ color: 'white', fontSize: 18, whiteSpace: 'nowrap' }}>{continent.name}</p>
                                </div>

                            </div>
                        )
                    })}
                    {/* <div /> */}
                    {/* <div className="border border-3 border-white rounded-3" style={{width: "90%", height: 250, paddingLeft: 15, paddingRight: 15, marginTop: 25, marginBottom: 50 }}>
              <img src={SouthAmerica.src} alt="South America"/>
          </div>
          <div className="border border-3 border-white rounded-3" style={{width: "90%", height: 250, paddingLeft: 15, paddingRight: 15, marginTop: 25, marginBottom: 50 }}>
              <img src={Asia.src} alt="Asia" />
          </div>
          <div className="border border-3 border-white rounded-3" style={{width: "90%", height: 250, paddingLeft: 15, paddingRight: 15, marginTop: 25, marginBottom: 50 }}>
              <img src={Europe.src} alt="Europe" />
          </div>
          <div className="border border-3 border-white rounded-3" style={{width: "90%", height: 250, paddingLeft: 15, paddingRight: 15, marginTop: 25, marginBottom: 50 }}>
              <img src={Australia.src} alt="Australia"/>
          </div>
          <div className="border border-3 border-white rounded-3" style={{width: "90%", height: 250, paddingLeft: 15, paddingRight: 15, marginTop: 25, marginBottom: 50 }}>
              <img src={Africa.src} alt="Africa"/>
          </div> */}
                </Carousel>
                {showArtist &&
                    <div style={{ width: "100%", height: 300 }}>
                        <div className="container">
                            {/* {artists } */}
                            <div style={{ color: "white" }}>
                                {/* <Carousel responsive={responsive} keyBoardControl={true} centerMode autoPlay={false}> */}

                                <ResponsiveCarousel axis="horizontal" autoPlay centerMode showThumbs={false} showIndicators={false} width={"100%"}>
                                    {filteredArtist.length > 0 ? filteredArtist.sort((a, b) => { return a.stake > b.stake ? -1 : 1 }).map((artist) => {
                                        return (
                                            <div key={artist.name} style={{ backgroundColor: "#e2e2e2", height: 280, width: 250, borderRadius: "5px" }}>
                                                <div className='d-flex align-items-center justify-content-center' style={{ height: 230, width: " 100%" }}>
                                                    <Link href={`/m/a/${artist.id}`} passHref>
                                                        {artist?.video ?
                                                            <ReactPlayer
                                                                style={{ borderRadius: "5px 5px 0 0" }}
                                                                height={230}
                                                                url={artist?.video}
                                                                loop={true}
                                                                controls={true}
                                                                muted={true}
                                                                playing={true}
                                                            /> :

                                                            <img className='cursor-pointer img-fluid' src={artist.image} alt="artist-image" style={{ height: 230, width: '100%', borderRadius: "5px 5px 0 0" }} />


                                                        }

                                                    </Link>
                                                </div>
                                                <div className='white-bg' style={{ height: 50, width: " 100%", borderRadius: "0 0 5px 5px " }}>
                                                    <div className='d-flex align-items-center justify-content-between mx-2 cursor-pointer' onClick={() => Router.push(`/m/a/${artist.id}`)}>
                                                        <p className='bold text-black m-0 my-3'>{artist.storeName ?? artist.name}</p>
                                                        <p className='cursor-pointer m-0 my-3'><MdKeyboardArrowRight size={24} color="#000" /></p>
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    }) : [<div className="p-2  white-bg " key="no-artist">
                                        <div style={{ backgroundColor: "#e2e2e2", borderRadius: "5px", overflow: 'hidden' }}>
                                            <h2>We are looking for unique artists from this region. </h2>
                                            <h4>If you are an artist or know one, kindly refer to us at hello@fleato.com</h4>
                                        </div>
                                    </div>]}
                                </ResponsiveCarousel>
                                {/* Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
        Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger. */}
                            </div>
                        </div>
                    </div>}
            </MapMobileWrapper>

        </>
    );
}

const ArtistCard = ({ artist }: { artist: ArtistProfile }) => {

    return (
        <div className="container-fluid " style={{}}>
            <div className="container-fluid  border border-3 border-secondary rounded-3 bg-white text-center " style={{ width: 150, height: 280 }} >
                <div className="w-100 h-50">
                    <img src={artist.avatarImage ? artist.avatarImage : artist.image} alt="Artist Profile Image" className='w-100 h-100 mt-1' />
                </div>
                <div className="row h-60 mt-2 align-text-bottom">
                    <h5 className="col text-dark ">{artist.name}</h5>
                    <p className="text-dark">{artist?.storeName}</p>
                    <a href="#" className="btn btn-primary h-20">View Collection</a>
                </div>
            </div>
        </div>
    )
}

export const AllArtistSlider = ({ artists, agencyId }: { artists: ArtistProfile[], agencyId?: string }) => {
    const [isLoading, setIsLoading] = useState(false);

    const Router = useRouter();
    return (
        <div>
            {/* <Carousel responsive={responsive} className="col-container" keyBoardControl={true} centerMode autoPlay={false}> */}
            <Wrapper>
                <div className="position-relative">

                    <div className="position-relative" >

                        <Carousel responsive={responsive} autoPlay={isLoading ? false : true} infinite={true} className="artistCollection" slidesToSlide={1} centerMode={true}>
                            {artists?.map((value) => {

                                return (
                                    <div key={`${value.image}`}>
                                        <div
                                            // style={{
                                            //     backgroundImage: `url(${value.image})`,
                                            //     backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
                                            // }}
                                            className="item cursor-pointer"
                                            onClick={() => { Router.push(`/m/a/${value.id}`); setIsLoading(!isLoading) }}
                                        >
                                            <Image src={value.image} width={400} height={400} objectFit="cover" quality={75} />
                                        </div>
                                        <div key={`${value.image}reflection`}
                                            style={{ transform: "rotateX(180deg)", backgroundPositionY: "360px" }}
                                            className="item-reflection d-none d-md-block"
                                        ></div>


                                    </div>
                                )
                            })}
                        </Carousel>

                        <div className=" d-md-block caption py-2">
                            <div className="container text-center text-md-start">
                                <div className="row">
                                    <div className="col-md-8">
                                        {/* <h3 className="bold mb-1">Featured Artists</h3> */}
                                    </div>
                                    <div className="col-md-4 text-center text-md-end">
                                        <button
                                            onClick={() => { Router.push(`/m/artists/${agencyId ?? ""}`) }}
                                            className="btn white-bg rounded mt-2 px-3 py-1 py-md-2 border-blue text-blue"
                                            type="button"
                                        >
                                            View Registry
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <ResponsiveCarousel axis="horizontal" autoPlay centerMode showThumbs={false} showIndicators={false} width={"100%"}>

                {allArtists?.map(artist => {
                    return (
                        // <div key={artist.name} className="col">
                        // <div key={artist.name} className="border border-3 border-dark rounded-3 h-75 artist-slider-pointer text-center pb-5" style={{ width: "95%", padding: 15, marginTop: 15 }} onClick={() => { Router.push(`/m/a/${artist?.id}`) }}>
                        //     <div className="w-100 h-30">
                        //         <img src={artist.avatarImage ? artist.avatarImage : artist.image} alt="Artist Profile Image" className='w-75 h-auto ' />
                        //     </div>
                        //     <div className="w-100 h-20 pb-3">
                        //         <p className="d-flex justify-content-center mt-1 bold" style={{ color: 'black', fontSize: 18, }}>{artist.name}</p>
                        //         <p className="d-flex justify-content-center pb-2" style={{ color: 'black', fontSize: 18, }}>{artist.storeName}</p>
                        //     </div>
                        // </div>
                        <div key={artist.name} style={{ backgroundColor: "#e2e2e2", height: 280, width: 250, borderRadius: "5px" }}>
                            <div className='d-flex align-items-center justify-content-center' style={{ height: 230, width: " 100%" }}>


                                <img className='cursor-pointer img-fluid' src={artist.image} style={{ height: 230, width: '100%', borderRadius: "5px 5px 0 0" }} />




                            </div>
                            <div className='white-bg' style={{ height: 50, width: " 100%", borderRadius: "0 0 5px 5px " }}>
                                <div className='d-flex align-items-center justify-content-between mx-2 cursor-pointer' >
                                    <p className='bold text-black m-0 my-3'>{artist.storeName ?? artist.name}</p>
                                    <p className='cursor-pointer m-0 my-3'><MdKeyboardArrowRight size={24} color="#000" /></p>
                                </div>
                            </div>
                        </div>
                        // </div>
                    )
                })}
                {/* </Carousel> */}


                        {/* </ResponsiveCarousel> */}

                    </div>
                </div>
            </Wrapper>
        </div>

    )
}


