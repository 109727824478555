import styled from "styled-components";

const OffersWrapper = styled.div`
.grey-bg{
background-color:#f2f2f2;
}
.glassmorphic{
background-color:rgba(0, 0, 0, 0.367);
}
.white-ghost-btn{
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        color: #FFFFFF;
        border: 3px solid #FFFFFF;
        border-radius: 2px 2px 2px 2px;
        padding: 10px 20px 10px 20px;
      }
      .blue-ghost-btn{
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        color: #fff;
        border-radius: 2px 2px 2px 2px;
        padding: 10px 20px 10px 20px;
        background:#1478dd;
      }
.dark-bg{
    background-color:#dce0e6;
}
.bottom-container{
    gap:0.5rem;
        margin-top:0.5rem;
        @media(min-width:768px){
            .col-sm-4{
                width:32.4%;
            }
        }
}
.px-20{
    padding:0 20px;
}
.bottom-container{
    .col-lg-4{
        // min-height: 200px;
        @media(min-width:768px){
            min-height:200px;
        }        
        @media(min-width:920px){
            width:32.4%;
            min-height:400px;
            }
    }
   
    .align-items-stretch{
        @media(max-width:768px){
        div{
            min-height:200px;
        }
        } 
    }
}
.top-container{
    gap:0.5rem;
    @media(max-width:720px){
        margin:0.5rem;
    }
    .col-lg-8{
        min-height: 400px;
        @media(min-width:920px){
        width:65.4%;
        }
    }
    .col-lg-4{
        min-height: 400px;
        @media(min-width:768px){
            min-height:200px;
        }        
        @media(min-width:920px){
            width:32.4%;

            }
    }
    .max-50{
        max-width:50%;
    }
}
.small-font{
    font-size:1.5rem;
}
.large-font{
    font-size:1.8rem;
    line-height:1.2;
    @media(min-width:920px){
    font-size:2rem;
    line-height:1.2;
    }
}
.large-font-title{
    font-size:2rem;
    @media(min-width:920px){
    font-size:2.5rem;
    }
}
.col-sm-4{
    min-height:300px;
    @media(min-width:720px){
        width:32%;
    }
}
@media(min-width:768px){
    .h-md-100{
        height:100% !important;
    }
}
@media(min-width:920px){
    .h-md-100{
        height:50% !important;
    }
}
`;

const MerchWrapper = styled.div`
.merch-bg{
    position:absolute;
    width:100%;
    height:100%;
    background-color:#d7e6f5;
    top:0;
    left:0;
    z-index:0;

}

.zIndex-1{
    z-index:1;
}
.merch-grid{
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-template-rows:1fr 1fr;
    justify-content:center;
    align-content:center;
    align-items:center;
    justify-items:center;
}
.merch-grid-item{
    position:relative;
    text-align:center;
    cursor:pointer;
    transition: opacity 200ms ease-out;

}
.merch-grid-item:hover .img-container{
    transform:scale(1.2);
  box-shadow: rgb(0 0 0 / 32%) 0px 3px 6px;

}

.title{
    font-weight: 700;
}
.merch-grid-item:hover .title::after{
    content:'';
    position:absolute;
    bottom:10px;
    left:0;
    width:100%;
    height:2px;
    background:#000;
}
.merch-grid-item:hover .title{
    color:#585858;
}
.img-container{
    width: 100px;
    height: 100px;
    border-radius:50%;
    background:#fff;
    margin-bottom:10px;
    overflow:hidden;
    background-repeat:no-repeat;
    background-size:cover;
}
@media(min-width:600px){
    .merch-grid{
        // grid-template-columns:1fr 1fr 1fr 1fr 1fr;
        grid-template-rows:1fr;
    }
    .merch-bg{
        height:75%;
        position:absolute;
        width:100%;
        background-color:#d7e6f5;
        top:0;
        left:0;
        z-index:0;
        transform:translateY(-15%);
    
    }
}
`;
export { OffersWrapper, MerchWrapper };